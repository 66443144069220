<template>
  <div>
    <van-dialog @confirm="onSubmit" v-model:show="pageStatus" title="提示" show-cancel-button>
      <div style="padding: 20px 0">
        <van-field
            v-model="form.bao_jia"
            placeholder="请输入您的预估金额"
            ref="input"
        />
      </div>
    </van-dialog>

  </div>
</template>

<script>
import AlertButton from "../../open/alertButton";
import http from "../../../api/http";
import din_shi from "../../../api/din_shi";

export default {
  name: 'canYuBaoJia',
  components: {AlertButton},
  mixins: [],
  data() {
    return {
      id: Number, //需求id
      form: {},
      pageStatus: false
    }
  },
  mounted() {
  },
  methods: {
    onSubmit() {
      this.form.id = this.id
      http.post("/gzhphp/user_xiang_mu/canYuXiangMu", this.form).then(da => {
        if (da.code === 1) {
          this.pageStatus = false
          din_shi.success(da.msg).then(da => {
            location.reload()
          })
        }
      })
    },
    show(id) {
      this.id = id
      this.pageStatus = true
      this.$nextTick(() => {
        this.$refs.input.focus()
      })
    },
  },
  watch: {},
  computed: {}
}
</script>

<style scoped>

</style>
