<template>
  <div>
    <van-action-sheet v-model:show="pageStatus">
      <van-form @submit="onSubmit">
        <van-cell-group inset>
          <van-field
              required
              v-model="form.message"
              rows="2"
              label="反馈内容"
              type="textarea"
              placeholder="请输入反馈内容"
          />
          <van-field
              required
              :readonly="isAutoMobile"
              v-model="form.mobile"
              label="手机号码"
              placeholder="请输入您的手机号码"

          />
          <van-field
              required
              v-model="form.code"
              label="验证码"
              placeholder="请输入验证码"
              v-if="!isAutoMobile"
          >
            <template #button>
              <a href="javascript:" @click="getCode(form.mobile)">{{ getCodeTitle }}</a>
            </template>
          </van-field>
          <model-and-code/>
          <alert-button>
            提交
          </alert-button>
        </van-cell-group>
      </van-form>
    </van-action-sheet>
  </div>
</template>

<script>

import AlertButton from "./alertButton";
import yanZhengMa from "./yanZhengMa";
import http from "../../api/http";

export default {
  name: 'fanKui',
  components: {AlertButton},
  mixins: [yanZhengMa],
  props:{
    /**
     * 默认留言
     * 反馈
     */
    dataType:String,
  },
  data() {
    return {
      form: {},
      pageStatus: false
    }
  },
  methods: {
    show() {
      this.pageStatus = true
      this.getMobiel().then(mobile => {
        this.form.mobile = mobile
      })
    },
    onSubmit() {
      http.post('/gzhphp/fu_wu_shang/addFanKui', this.form, {
        load_success: true
      }).then(da => {
        if (da.code === 1) {
          this.pageStatus = false
          this.form.message = ''
        }
      })
    }
  },
  computed:{
    langVal(){

    }
  }
}
</script>
<style scoped>

</style>
