<template>
  <div class="bu-zhou">
    <div class="bu-zhou-item" v-for="(vo,index) in getList" :key="index">
      <div class="bu-zhou-item-number">
        <div
            class="yuan-quan"
            :style="{'opacity':vo.opacity}"
        >
          {{ vo.index }}
        </div>
      </div>
      <div class="bu-zhou-item-name">
        {{ vo.name }}
      </div>
    </div>
  </div>
</template>

<script>
import XEUtils from "xe-utils";

export default {
  name: 'buZhou',
  mixins: [],
  props: {
    /*
      格式
    * [
    *   {name:'发布需求'},
    *   {name:'发布需求'}
    * ]
    * */
    list: Array

  },
  mounted() {
  },
  methods: {},
  watch: {},
  computed: {
    getList() {
      let list = this.list.map((n, i) => {
        return {
          name: n,
          index: i + 1,
          opacity: (i + 1) / this.list.length
        }
      })

      return list
    }
  }
}
</script>

<style scoped lang="less">
@import "../../../assets/basis_css/style/var";

.bu-zhou {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-evenly;
  align-items: center;
  margin: 10px 0;

  .bu-zhou-item {
    .yuan-quan {
      width: 26px;
      height: 26px;
      background-color: @pei-se;
      border-radius: 100%;
      margin: auto;
      color: @white;
      text-align: center;
      line-height: 26px;
    }
    .bu-zhou-item-name{
      margin-top: 6px;
      color: @gray-6;
    }
  }
}
</style>
