<template>
  <div class="text-center" :class="{'nei-rong':fixed}" :style="style">
    <van-row>
      <van-col :span="8">
        <a href="javascript:" @click="onSelect('在线客服')">在线客服</a>
      </van-col>
      <van-col :span="8"><a href="javascript:" @click="onSelect()">留言反馈</a></van-col>
      <van-col :span="8">
        <a href="javascript:" @click="onSelect('客服电话')">客服电话</a>
      </van-col>
    </van-row>
    <fan-kui ref="liuYan"/>
  </div>
</template>

<script>
import http from "../../api/http";
import FanKui from "@/views/open/fanKui";

export default {
  name: 'footerLianXikeFu',
  components: {FanKui},
  mixins: [],
  props:{
    fixed:{
      type:Boolean,
      default(){
        return true
      }
    },
    style:[Object,String]
  },
  mounted() {
  },
  methods: {
    onSelect(type = '') {
      if (type === '在线客服') {
        http.get('/portal/index/getKeFuUrl').then(da => {
          if (da.code === 1) location.href = da.data.content
        })
        return
      }

      if (type === '客服电话') {
        http.get('/portal/index/get_ke_hu_tel').then(da => {
          if (da.code === 1) location.href = `tel:${da.data.content}`
        })
        return
      }

      this.$refs.liuYan.show()

    }
  },
  watch: {},
  computed: {}
}
</script>

<style scoped>
.nei-rong {
  position: fixed;
  bottom: 64px;
  width: 100%;
}
</style>
