<template>
  <div class="fen-ge" :class="size">
    <van-row>
      <van-col :span="18">
        <slot></slot>
      </van-col>
      <van-col :span="6">
        <slot name="right"></slot>
      </van-col>
    </van-row>
  </div>
</template>

<script>
export default {
  name: 'FenGeFu',
  mixins: [],
  props: {
    //类型有lg
    size: String
  },
  mounted() {
  },
  methods: {},
  watch: {},
  computed: {}
}
</script>


<style scoped lang="less">
@import "../../assets/basis_css/style/var.less";

.fen-ge {
  border-left: 2px solid @blue;
  height: 15px;
  line-height: 16px;
  padding-left: 8px;
  color: @blue;
  font-weight: 600;
  &.lg{
    height: 18px;
    line-height: 18px;
    font-size: 18px;
  }
}
</style>
