<template>
  <div class="prohibit-copy" oncontextmenu="return false;">
    <nav-header :title="info?.title" fixed/>
    <div class="xiang-xi">
      <div class="info-item">
        <div class="info-item-title" style="width: 100%;">
          用户：{{ keName }}于{{ addCreatetime }} 发布
        </div>
      </div>
      <div class="info-item">
        <div class="info-item-title">
          需求标题：
        </div>
        <div class="info-item-val">
          {{ info?.title }}
        </div>
      </div>
      <div class="info-item">
        <div class="info-item-title">
          项目类型：
        </div>
        <div class="info-item-val">
          {{ info?.type_name }}
        </div>
      </div>
      <div class="info-item">
        <div class="info-item-title">
          项目地点：
        </div>
        <div class="info-item-val">
          {{ info?.address_name }}
        </div>
      </div>

      <div class="info-item">
        <div class="info-item-title">
          项目描述：
        </div>
        <div class="info-item-val">
          {{ info?.miao_shu }}
        </div>
      </div>
      <div class="info-item jia-ge-item">
        <div class="info-item-title">
          服务金额：
        </div>
        <div class="info-item-val jia-ge">
          {{ jieGe }}
        </div>
      </div>
    </div>
    <div style="margin: 20px 0">
      <bu-zhou :list="['发布需求','完善内容','匹配服务商','签约合作','验收成功']"/>
    </div>
    <div class="can-yu-fu-wu-shang">
      <fen-ge-fu size="lg">参与的服务商（ {{ getFws.length }} ）</fen-ge-fu>
      <van-empty v-if="!getFws.length"/>
      <div class="fws-list"
           v-for="vo in getFws"
           :key="vo.id"
      >
        <div class="fws-list-item" @click="$router.push({name:'FuWuShangInfo',query:{g_id:vo.g_id}})">
          <div class="fws-list-item-logo">
            <van-image round :src="vo.join_min_g_id.logo" width="60" height="60"/>
          </div>
          <div class="fws-list-item-info">
            <div class="name">
              {{ vo?.join_min_g_id?.g_name }}
            </div>
            <div class="bao-jia" :class="{'dui-hao': vo.status=== '选中'}">
              报价金额：{{ vo?.bao_jia }}
            </div>
            <div class="create-time">
              <div>
                参与时间：{{ vo?.create_time }}
              </div>
              <div style="text-align: right" v-if="isXuanBiao">
                <a href="javascript:" @click.stop="setZhongBiao(vo.id)">选他合作</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isCanYu">
      <footer-gou-mai-btn
          :btn-list="benList"
          @lol-click="editBtn"
      />
    </div>
    <lian-xi-ke-fu ref="keFu"/>
    <can-yu-bao-jia ref="canYu"/>
  </div>
</template>

<script>
import NavHeader from "../../components/top/NavHeader";
import XEUtils from "xe-utils";
import http from "../../api/http";
import moment from "moment";
import {hidden, unitConvert} from "../../api/GongJu";
import BuZhou from "./public/buZhou";
import FenGeFu from "../open/FenGeFu";
import FooterLianXikeFu from "../open/footerLianXikeFu";
import LianXiKeFu from "../open/LianXiKeFu";
import CanYuBaoJia from "./public/canYuBaoJia";
import din_shi from "../../api/din_shi";
import FooterGouMaiBtn from "../../components/btn/footerGouMaiBtn";
import {getUserInof} from "../../api/cookie";

export default {
  name: 'XiangMuInfo',
  components: {FooterGouMaiBtn, CanYuBaoJia, LianXiKeFu, FooterLianXikeFu, FenGeFu, BuZhou, NavHeader},
  mixins: [],
  data() {
    return {
      lxrInfo: getUserInof(),
      info: {},
      id: XEUtils.toNumber(this.$route.params.xiang_mu_id)
    }
  },
  mounted() {
    this.getInfo()
  },
  methods: {
    editBtn(item) {
      if (item.type === '客服电话') {
        this.$refs.keFu.onSelect({type: '客服电话'})
        return
      }
      if (item.type === '在线客服') {
        this.$refs.keFu.onSelect({type: '在线客服'})
        return
      }

      if (item.type === '参与项目') {
        this.canYu()
        return
      }

    },
    getInfo() {
      http.get("/gzhphp/xiang_mu/getInfo", {
        params: {id: this.id}
      }).then(da => {
        if (da.code === 1) {
          this.info = da.data
        }
      })

    },
    canYu() {
      this.$refs.canYu.show(this.info.id)
    },
    setZhongBiao(can_yu_id) {
      http.post("/gzhphp/user_xiang_mu/zhongBiao", {can_yu_id: can_yu_id}).then(da => {
        if (da.code === 1) {
          din_shi.success(da.msg).then(da => {
            this.getInfo()
          })
        }
      })
    }
  },
  watch: {},
  computed: {
    benList() {
      let data = [
        {
          icon: 'iconfont tu-biao icon-dianhua',
          title: '联系电话',
          type: '客服电话'
        },
        {
          icon: 'iconfont tu-biao icon-kefu',
          title: '在线客服',
          type: '在线客服'
        }
        /*{
          title: '参与项目',
          type: '参与项目'
        }*/
      ]

      if (XEUtils.toInteger(this.info?.ywht_id) > 0) {
        data.push({
          title: '已找到服务商',
          type: '已找到服务商'
        })
      } else {
        data.push({
          title: '参与项目',
          type: '参与项目'
        })
      }
      return data
    },
    addCreatetime() {
      if (XEUtils.isEmpty(this.info)) return ''
      return moment(this.info.create_time * 1000).format("YYYY-MM-DD")
    },
    keName() {
      if (XEUtils.isEmpty(this.info)) return ''
      return hidden(this.info?.join_min_lxr_id?.user_nickname, 1, 1, '*');
    },
    jieGe() {
      if (XEUtils.isUndefined(this.info.money)) return ''
      if (this.info.money <= 0) {
        return '可议价'
      }
      return unitConvert(this.info.money)

    },
    getFws() {
      if (!XEUtils.isArray(this.info?.join_sj_xu_qiu_id)) return []
      return this.info?.join_sj_xu_qiu_id.map(n => {
        n.create_time = moment(n.create_time * 1000).format('YYYY-MM-DD HH:mm')

        if (n.bao_jia > 0) {
          n.bao_jia = unitConvert(XEUtils.toInteger(n.bao_jia))
        } else {
          n.bao_jia = '***';
        }
        return n
      })
    },
    //是否中标 true=已中标
    isZhongBiao() {
      if (XEUtils.isEmpty(this.info)) return false
      return XEUtils.toInteger(this.info.ywht_id) !== 0
    },
    //是否为需求操作人 true 可以选服务商
    isXuanBiao() {
      if (this.isZhongBiao) {
        return false
      }
      return XEUtils.toInteger(this.lxrInfo?.id) === XEUtils.toInteger(this.info.cao_zuo_ren_id)
    },
    //是否可以参与报价 true= 可以参与报价
    isCanYu() {
      return this.lxrInfo?.dang_qian_jiao_se !== '客户'
    }
  }
}
</script>


<style scoped lang="less">
@import "../../assets/basis_css/style/var";

.xiang-xi {
  margin: 10px;
  background-color: @white;
  padding: 13px 16px;
  box-shadow: @yin-ying;
  font-size: 16px;
  border-radius: 10px;

  .biao-ti {
    font-weight: bold;
  }

  .info-item {
    display: flex;
    margin-top: 10px;

    &.jia-ge-item {
      align-items: flex-end;
    }

    .info-item-title {
      width: 90px;
    }

    .info-item-val {
      flex: 1;
      color: @gray-6;

      &.jia-ge {
        color: @red;
        font-size: 20px;
        font-weight: bold;
      }
    }

  }

}

.can-yu-fu-wu-shang {
  margin: 10px;
  box-shadow: @yin-ying;
  border-radius: 10px;
  background-color: @white;
  padding: 13px 16px;
  margin-bottom: 20px;

  .fws-list-item {
    display: flex;
    margin-top: 10px;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 5px;
    border-bottom: 1px dashed @gray-4;

    .fws-list-item-logo {
      width: 70px;
    }

    .name {
      font-size: 16px;
    }

    .create-time {
      display: flex;
      justify-content: space-between;
    }

    .dui-hao {
      background-image: url("../../assets/icon/dui-hao.png");
      background-size: 20px;
      background-repeat: no-repeat;
      background-position-x: right;
      //background-position-y:aotu;
    }

    .name, .bao-jia, .create-time {
      margin: 5px 0;
    }

    .create-time, .bao-jia {
      color: @gray-6;
      font-size: 14px;
    }
  }

}

.fws-list-item-info {
  flex: 1;
}


</style>
